import './style/main.css'

async function init () {
    const node = document.querySelector("#type-text")

    await sleep(1000)
    node.innerText = ""
    await node.type('')

    while (true) {
        await node.classList.add('text-blueTitle')
        await node.type('Healthcare')
        await sleep(2000)
        await node.delete('Healthcare')
        await node.classList.remove('text-blueTitle')
        await node.classList.add('text-pink')
        await node.type('Education')
        await sleep(2000)
        await node.delete('Education')
        await node.classList.remove('text-pink')
        await node.classList.add('text-greenTitle')
        await node.type('Environment')
        await sleep(2000)
        await node.delete('Environment')
        await node.classList.remove('text-greenTitle')
    }
}


// Source code 🚩

const sleep = time => new Promise(resolve => setTimeout(resolve, time))

class TypeAsync extends HTMLSpanElement {
    get typeInterval () {
        const randomMs = 100 * Math.random()
        return randomMs < 50 ? 10 : randomMs
    }

    async type (text) {
        for (let character of text) {
            this.innerText += character
            await sleep(this.typeInterval)
        }
    }

    async delete (text) {
        for (let character of text) {
            this.innerText = this.innerText.slice(0, this.innerText.length -1)
            await sleep(this.typeInterval)
        }
    }
}

customElements.define('type-async', TypeAsync, { extends: 'span' })


init()